import Vue from 'vue'
import router from './router'
import store from './store'

import NProgress from 'nprogress'
import '@/components/NProgress/nprogress.less'

NProgress.configure({ showSpinner: false })
let defaultRoutePath = '/'
const whiteList = ['Login', 'ForgotPassword','ResetPassword', 'CreateOrganization', 'EmailVerified']

router.beforeEach((to, from, next) => {
    const outer = to.meta?.outer;
    const token = Vue.ls.get('Authorization');
    if (outer && token) {
        const lang = localStorage.getItem('locale');
        let a = document.createElement('a');
        a.target = '_blank';
        a.href = process.env.VUE_APP_VERSION_2 + to.path + '?access_token=' + token + '&lang=' + lang;
        a.click();
        next(from);
    }
    if (to.path === "/verify") {
        next();
    } else {
        NProgress.start()
      if (token) {
        if (whiteList.includes(to.name)) {
            if (to.name === 'CreateOrganization' || to.name === 'ResetPassword') {
                if(to.query.token) {
                    next()
                }
            } else {
                if (to.name === 'EmailVerified') {
                    next();
                } else {
                    next({ path: defaultRoutePath })
                }
            }
          NProgress.done()
        } else {
            if (store.getters['getRole'] === null) {
                store.dispatch('GetLoggedUser')
                    .then(res => {
                        const role = res.data.role.id
                        store.dispatch('GenerateRoutes', { role }).then(() => {
                            let roleRoutePath = ''
                            let me = store.getters['me']
                            router.addRoutes(store.getters['addRouters'])
                            //roleRoutePath = role === 1 ? '/dashboard/organization-list': '/dashboard/profile'
                            roleRoutePath = '/dashboard/organization-list';
                            if (typeof me === "object" && me.password_expired && to.path !== '/password/change') {
                                next({ path: '/password/change' })
                            } else if (to.path === defaultRoutePath) {
                                next({ path: roleRoutePath })
                            } else  {
                                next({ ...to, replace: true })
                            }
                        })
                    })
                    .catch(() => {
                        store.dispatch('Logout').then(() => {
                            next({ path: '/user/login', query: { redirect: to.fullPath } })
                        })
                    })
            } else {
                next()
            }
        }
      } else {
          if (whiteList.includes(to.name)) {
              if (to.name === 'CreateOrganization' || to.name === 'ResetPassword') {
                  if(to.query.token) {
                      next()
                  } else {
                      next({ path: '/user/login', query: { redirect: to.fullPath } })
                  }
              } else next()
          } else {
              next({ path: '/user/login', query: { redirect: to.fullPath } })
              NProgress.done()
          }
      }
    }
})

router.afterEach(() => {
  NProgress.done()
})
