import { axios } from '@/utils/request';

export function getUsers(searchParams) {
    let params = {
        page: searchParams?.page ? searchParams.page : 1,
        ...searchParams?.pageSize ? { per_page: searchParams.pageSize } : {},
        ...searchParams?.organization ? { organization_id: searchParams.organization } : {},
        ...searchParams?.organizationStatus ? { statuses: searchParams.organizationStatus } : {},
        ...searchParams?.sortField ? { order_column: searchParams.sortField } : {},
        ...searchParams?.sortOrder ? { order_direction: searchParams.sortOrder } : {},
        ...searchParams?.search ? { search: searchParams.search } : {},
    };

    const genParams = () => {
        return Object.keys(params).map(key => {
            if (key == 'statuses') {
                return params[key].map(item => {
                    return `${key}[]=${item}`
                }).join('&')
            } else {
                return `${key}=${params[key]}`
            }
        }).join('&')
    }

    return axios({
        url: `/users?${genParams()}`,
        method: 'get'
    })
}

export function getListForOrganization(searchParams) {
    const genParams = () => {
        return Object.keys(searchParams).map(key => `${key}=${searchParams[key]}`).join('&')
    }

    return axios({
        url: `/users/organization-list?${genParams()}`,
        method: 'get'
    })
}

export function getUser(id, payload){
    return axios({
        url: `/users/${id}?${payload}`,
        method: 'get'
    })
}

export function getUsersByEmail(email){
    return axios({
        url: `/users/${email}`,
        method: 'get'
    })
}

export function getUsersByEmailWithToken(token, email){
    return axios({
        url: `/users/${token}/${email}`,
        method: 'get'
    })
}

export function deleteUser(id, payload){
    return axios({
        url: `/users/${id}?${payload}`,
        method: 'delete'
    })
}

export function createUser(data) {
    return axios({
        url: `/users`,
        method: 'post',
        data: data
    });
}

export function updateUser(id, data){
    return axios({
        url: `/users/${id}`,
        method: 'patch',
        data: data
    })
}

export function importUsers(data){
    return axios({
        url: `/users/import`,
        method: 'post',
        data: data,
    })
}