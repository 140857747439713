import { getOrganizations, getOrganizationsDropdown } from '@/api/organizations';

const organizations = {
    namespaced: true,
    state: {
        organizationsList: {},
        pageSize: 12,
        pageNumber: 1,
        filters: {},
        organizations: [],
        organizationsWithoutLink: [],
        sortBy: {},
        searchValue: ''
    },
    getters: {
        getOrganizationsList: state => state.organizationsList,
        getOrganizationsTableData: state => state.organizationsList.data,
        getPageSize: state => state.pageSize,
        getPageNumber: state => state.pageNumber,
        getOrganizations: state => state.organizations,
        getOrganizationsWithoutLink: state => state.organizationsWithoutLink,
    },
    mutations: {
        SET_ORGANIZATIONS_LIST(state, data){
            state.organizationsList = data
        },
        SET_PAGE_NUMBER(state, data) {
            state.pageNumber = data
        },
        SET_PAGE_SIZE(state, data) {
            state.pageSize = data
        },
        SET_ORGANIZATIONS_DROPDOWN_LIST(state, data) {
            state.organizations = data
        },
        SET_ORGANIZATIONS_DROPDOWN_LIST_WITHOUT_LINK(state, data) {
            state.organizationsWithoutLink = data
        },
        SET_FILTERS(state, data){
            state.filters = data
        },
        SET_SORT(state, data){
            state.sortBy = data
        },
        SET_SEARCH_VALUE(state, data) {
            state.searchValue = data
        }
    },
    actions: {
        GetOrganizationsList: (context, params) => {
            return new Promise((resolve, reject) => {
                getOrganizations({
                    ...params,
                    pageSize: context.state.pageSize,
                    ...params?.organizationStatus
                        ? { organizationStatus: params.organizationStatus }
                        : { organizationStatus: context.state.filters.organizationStatus },
                    ...params?.organization
                        ? { organization: params.organization }
                        : { organization: context.state.filters.organization },
                    ...params?.sortField
                        ? { sortField: params.sortField, sortOrder: params.sortOrder }
                        : { sortField: context.state.sortBy.sortField, sortOrder: context.state.sortBy.sortOrder },
                    ...params?.search
                        ? { search: params.search }
                        : { search: context.state.searchValue }
                }).then(response => {
                    context.commit('SET_PAGE_NUMBER', response.meta.current_page)
                    context.commit('SET_ORGANIZATIONS_LIST', response)
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },

        GetOrganizationDropdownList: (context, data) => {
            return new Promise((resolve, reject) => {
                getOrganizationsDropdown(data).then(response => {
                    context.commit('SET_ORGANIZATIONS_DROPDOWN_LIST', response.data)
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
                // set organizations without link
                getOrganizationsDropdown(data, false).then(response => {
                    context.commit('SET_ORGANIZATIONS_DROPDOWN_LIST_WITHOUT_LINK', response.data)
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        }
    }
}

export default organizations