<template>
  <div class="auth-wrapper" :style="{backgroundImage: 'url(' + getBgImgPath + ')'}">
    <div class="content content-auth">
      <div class="logo">
        <router-link :to="{path:'/'}">
          <img src="@/assets/text-logo.svg" alt="logo">
        </router-link>
      </div>
      <route-view></route-view>
      <div class="auth-contact">{{$t('login.contact_us')}} <a href="mailto:support@osol.fr">support@osol.fr</a> </div>
    </div>
    <div class="content content-image">
    </div>
  </div>
</template>

<script>
import {mapGetters, mapMutations} from 'vuex'
import RouteView from './RouteView'

export default {
  name: 'AuthLayout',
  components: {
    RouteView
  },
  computed: {
    ...mapGetters(['getBgImgPath']),
  },
  methods: {
    ...mapMutations(['SET_BG_IMAGE_PATH'])
  },
  watch: {
    $route (to) {
      switch (to.name) {
        case "Login":{
            this.SET_BG_IMAGE_PATH("/auth_images/White4.png");
          }
          break;
          default: {
            this.SET_BG_IMAGE_PATH("/auth_images/ResetPassword.png");
          }
          break;
      }
    }
  }
}
</script>

<style lang="less">
.auth-layout {
  &__title {
    font-size: 22px;
    font-weight: 600;
  }

  &__action {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__form {
    max-width: 600px;
  }

  &__text {
    margin-bottom: 35px;
  }
}

.auth-wrapper {
  display: flex;
  width: 100%;
  height: 100%;
  background-position: top right;
  background-size: contain;
  background-repeat: no-repeat;

  .ant-form-item-label {
    line-height: 30px;
  }

  .ant-row {
    margin-top: 20px;
    margin-bottom: 30px;
  }
}
</style>

<style lang="less" scoped>
  .logo {
    display: block;
    padding: 10px 0 90px;
  }

  .content {
    width: 50%;

    &-auth {
      padding: 30px 80px;
      display: flex;
      flex-direction: column;
    }

    &-image {
      img {
        width: 100%;
      }
    }
  }

  .auth-contact {
    font-size: 11px;
    margin-top: auto;
    padding-top: 20px;
  }
</style>
