import Vue from 'vue'
import VueStorage from 'vue-ls'

// base library
import Antd from 'ant-design-vue'
import 'ant-design-vue/dist/antd.less'

// Maps integration, icons issues resolve
import "leaflet/dist/leaflet.css";
import { Icon } from "leaflet";
delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png")
});

const storageOptions =  {
    namespace: 'pro__', // key prefix
    name: 'ls', // name variable Vue.[ls] or this.[$ls],
    storage: 'local' // storage name session, local, memory
}
import moment from 'vue-moment';

// sockets
import Socket from './echo';

Vue.use(Socket);
Vue.use(moment);
Vue.use(Antd)
Vue.use(VueStorage, storageOptions)
