import { axios } from '@/utils/request';

export function getOrganizations(searchParams) {
    let params = {
        page: searchParams?.page ? searchParams.page : 1,
        ...searchParams?.pageSize ? { per_page: searchParams.pageSize } : {},
        ...searchParams?.organization ? { organization_id: searchParams.organization } : {},
        ...searchParams?.organizationStatus ? { statuses: searchParams.organizationStatus } : {},
        ...searchParams?.sortField ? { order_column: searchParams.sortField } : {},
        ...searchParams?.sortOrder ? { order_direction: searchParams.sortOrder } : {},
        ...searchParams?.search ? { search: searchParams.search } : {},
    };

    const genParams = () => {
        return Object.keys(params).map(key => {
            if (key == 'statuses') {
                return params[key].map(item => {
                    return `${key}[]=${item}`
                }).join('&')
            } else {
                return `${key}=${params[key]}`
            }
        }).join('&')
    }

    return axios({
        url: `/organizations?${genParams()}`,
        method: 'get'
    })
}
export function getOrganizationsDropdown(searchParams, withLink = true) {
    let param = searchParams ? `search=${searchParams}`: '';
    param += withLink ? '&withLink' : ''
    return axios({
        url: `/organizations/dropdown?${param}`,
        method: 'get'
    })
}
export function sendCreateOrganizationLink(email) {
    return axios({
        url: '/organizations/send-link',
        method: 'post',
        data: email
    })
}

export function createOrganizations(data) {
    return axios({
        url: '/organizations',
        method: 'post',
        data: data
    })
}

export function createOrganizationsByToken(token, data) {
    return axios({
        url: `/organizations/${token}/create`,
        method: 'post',
        data: data
    })
}

export function getOrganization(id){
    return axios({
        url: `/organizations/${id}`,
        method: 'get'
    })
}

export function updateOrganization(id, data){
    return axios({
        url: `/organizations/${id}`,
        method: 'patch',
        data: data
    })
}

export function updateOrganizationAutomation(id, data){
    return axios({
        url: `/organizations/${id}/automation`,
        method: 'patch',
        data: data
    })
}

export function deleteOrganization(id){
    return axios({
        url: `/organizations/${id}`,
        method: 'delete'
    })
}

export function pushBadges(id){
    return axios({
        url: `/organizations/${id}/push-badges`,
        method: 'patch'
    })
}

export function updateImportTemplate(id, data){
    return axios({
        url: `/organizations/${id}/import-template`,
        method: 'post',
        data: data
    })
}

export function testConnectionAutomation(id, data){
    return axios({
        url: `/organizations/${id}/automation/test-connection`,
        method: 'post',
        data: data,
        timeout: 29000
    })
}

export function getSshKey(id){
    return axios({
        url: `/organizations/${id}/automation/ssh-key`,
        method: 'post',
        timeout: 29000
    })
}