import { axios } from '@/utils/request';

export function getGeneralStats(organizationId, params) {
    return axios.get(`/stats/${organizationId}/general?${objToQuery(params)}`);
}

export function getUserStats(organizationId, params){
    return axios.get(`/stats/${organizationId}/users?${objToQuery(params)}`);
}

export function getTimeStats(organizationId, params){
    return axios.get(`/stats/${organizationId}/time?${objToQuery(params)}`);
}

export function getPicoStats(organizationId, params) {
    return axios.get(`/stats/${organizationId}/picos?${objToQuery(params)}`);
}

function objToQuery(obj) {
    return Object.keys(obj).map(key => {
        if (key === 'date') {
            return Object.keys(obj[key]).map(item => {
                return `${key}[${item}]=${obj[key][item]}`
            }).join('&')
        } else {
            return `${key}=${obj[key]}`
        }
    }).join('&');
}