import { getBases, getBasesList, changePicosVersion, setScreensaver, setAutoReboot } from '@/api/bases';

const bases = {
    namespaced: true,
    state: {
        basesList: {},
        organizationsBasesList: [],
        pageSize: 12,
        pageNumber: 1,
        filters: {},
        sortBy: {},
        searchValue: ''
    },
    getters: {
        getBasesList: state => state.basesList,
        getOrganizationBasesList: state => state.organizationsBasesList,
        getBasesTableData: state => state.basesList.data,
        getPageSize: state => state.pageSize,
        getPageNumber: state => state.pageNumber,
    },
    mutations: {
        SET_BASES_LIST(state, data){
            state.basesList = data
        },
        SET_ORGANIZATION_BASES_LIST(state, data) {
            state.organizationsBasesList = data
        },
        SET_PAGE_NUMBER(state, data) {
            state.pageNumber = data
        },
        SET_PAGE_SIZE(state, data) {
            state.pageSize = data
        },
        SET_FILTERS(state, data){
            state.filters = data
        },
        SET_SORT(state, data){
            state.sortBy = data
        },
        SET_SEARCH_VALUE(state, data) {
            state.searchValue = data
        }
    },
    actions: {
        GetBasesList: (context, params) => {
            return new Promise((resolve, reject) => {
                getBases({
                    ...params,
                    pageSize: context.state.pageSize,
                    ...params?.organizationStatus
                        ? { organizationStatus: params.organizationStatus }
                        : { organizationStatus: context.state.filters.organizationStatus },
                    ...params?.organization
                        ? { organization: params.organization }
                        : { organization: context.state.filters.organization },
                    ...params?.sortField
                        ? { sortField: params.sortField, sortOrder: params.sortOrder }
                        : { sortField: context.state.sortBy.sortField, sortOrder: context.state.sortBy.sortOrder },
                    ...params?.search
                        ? { search: params.search }
                        : { search: context.state.searchValue }
                }).then(response => {
                    context.commit('SET_PAGE_NUMBER', response.meta.current_page)
                    context.commit('SET_BASES_LIST', response)
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        GetOrganizationBasesList: (context, organizationId) => {
            return new Promise((resolve, reject) => {
                getBasesList(organizationId).then(response => {
                    context.commit('SET_ORGANIZATION_BASES_LIST', response.data);
                    resolve(response);
                }).catch(error => {
                    reject(error);
                })
            })
        },
        ChangePicosVersion: (context, {baseId: baseId, version: version}) => {
            return new Promise((resolve, reject) => {
                changePicosVersion(baseId, {version: version}).then(response => {
                    resolve(response);
                }).catch(error => {
                    reject(error);
                })
            })
        },
        SetAutoReboot: (context, {baseId: baseId, time: time}) => {
            return new Promise((resolve, reject) => {
                setAutoReboot(baseId, {time: time}).then(response => {
                    resolve(response);
                }).catch(error => {
                    reject(error);
                })
            })
        },
        SetScreensaver: (context, {baseId: baseId, enable: enable}) => {
            return new Promise((resolve, reject) => {
                setScreensaver(baseId, {enable: enable}).then(response => {
                    resolve(response);
                }).catch(error => {
                    reject(error);
                })
            })
        },
    }
}

export default bases