<template>
  <a-layout>
    <side-bar></side-bar>
    <a-layout-content class="main admin-layout-content">
      <route-view></route-view>
    </a-layout-content>
  </a-layout>
</template>

<script>
import RouteView from './RouteView'
import SideBar from '@/components/Menu/SideBar'

export default {
  name: 'CreateOrganizationAdminLayout',
  components: {
    RouteView,
    SideBar
  }
}
</script>

<style lang="less" scoped>
.main {
  position: relative;
}

.ant-layout {
  &.ant-layout-has-sider {
    height: 100%;
  }
}
</style>
