import { asyncRouterMap, constantRouterMap } from '@/config/router.config'
const superAdmin = ['dashboard', 'organization-list', 'picos-list', 'bases-list', 'user-list', 'statistics', 'tickets']
//const admin = ['profile', 'picos-list', 'bases-list', 'user-list', 'statistics']
const admin = ['organization-list', 'picos-list', 'bases-list', 'user-list', 'statistics']

function hasPermission (permission, route) {
    if (route.meta && route.meta.permission) {
        let flag = false
        for (let i = 0, len = permission.length; i < len; i++) {
            flag = route.meta.permission.includes(permission[i])
            if (flag) {
                return true
            }
        }
        return false
    }
    return true
}

function filterAsyncRouter (routerMap, role) {
    var permissionList = []
    permissionList = role === 1 ? superAdmin : admin
    const accessedRouters = routerMap.filter(route => {
        if (hasPermission(permissionList, route)) {
            if (route.children && route.children.length) {
                route.children = filterAsyncRouter(route.children, role)
            }
            return true
        }
        return false
    })
    return accessedRouters
}

const permission = {
    state: {
        routers: constantRouterMap,
        addRouters: []
    },
    getters: {
        addRouters: state => state.addRouters,
    },
    mutations: {
        SET_ROUTERS: (state, routers) => {
            state.addRouters = routers
            state.routers = constantRouterMap.concat(routers)
        }
    },
    actions: {
        GenerateRoutes ({ commit }, data) {
            return new Promise(resolve => {
                const accessedRouters = filterAsyncRouter(asyncRouterMap, data.role)
                commit('SET_ROUTERS', accessedRouters)
                resolve()
            })
        }
    }
}

export default permission