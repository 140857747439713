import { createBase, getBase, updateBase, deleteBase, releaseSlot } from '@/api/bases';
import store from '@/store'

const detailBase = {
    namespaced: true,
    state: {
        baseDetails: {},
        baseStatus: null,
        baseOrganizationData: {}
    },
    getters: {
        getBaseDetails(state) {
            return state.baseDetails
        },
        getBaseStatus(state) {
            return state.baseStatus
        },
        getBaseOrganizationData(state) {
            return state.baseOrganizationData
        },
    },
    mutations: {
        SET_BASE_DETAIL(state, data) {
            if ( data.lockers ) {
                data.lockers = JSON.parse(data.lockers);
            }
            state.baseDetails = data;
        },
        SET_BASE_STATUS(state, data) {
            state.baseStatus = data;
        },
        SET_BASE_ORGANIZATION_DATA(state, data) {
            state.baseOrganizationData = data;
        },
        DELETE_BASE_DETAIL_PICO(state, position) {
            var index = state.baseDetails.picos.findIndex((x) => x.tech_info && x.tech_info.position === position);
            if ( index > -1 )
            {
                state.baseDetails.lockers[position] = 0;
                state.baseDetails.picos.splice(index, 1);
                state.baseDetails.pico_number -= 1;
            }
        },
        CLEAR_BASE_DETAIL_PICOS(state) {
            for ( var key in state.baseDetails.lockers ) state.baseDetails.lockers[key] = 0;
            state.baseDetails.picos.splice(0, state.baseDetails.picos.length);
        }
    },
    actions: {
        CreateBase(context, data) {
            return new Promise((resolve, reject) => {
                createBase(data).then(()=> {
                    resolve()
                }).catch(error => {
                    reject(error)
                })
            })
        },
        GetBase: (context, id) => {
            return new Promise((resolve, reject) => {
                getBase(id).then(response => {
                    context.commit('SET_BASE_DETAIL', response.data)
                    context.commit('SET_BASE_STATUS', response.data.status.id)
                    if(!!response.data.organization)
                    {
                        store.dispatch('detailOrganization/GetOrganization', response.data.organization.id)
                            .then(res => {
                                context.commit('SET_BASE_ORGANIZATION_DATA', res.data)
                            })
                    }
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },

        UpdateBase: (context, param) => {
            return new Promise((resolve, reject) => {
                updateBase(param.id, param.data).then(response => {
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },

        DeleteBase: (context, id) => {
            return new Promise((resolve, reject) => {
                deleteBase(id).then(response => {
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },

        ReleaseSlot: (context, params) => {
            return new Promise((resolve, reject) => {
                releaseSlot(params.id, params.slot).then(response => {
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },

        DeleteBaseDetailPico: (context, position) => {
            context.commit('DELETE_BASE_DETAIL_PICO', position)
        },

        ClearBaseDetailPicos: (context, position) => {
            context.commit('CLEAR_BASE_DETAIL_PICOS', position)
        },
    }
}

export default detailBase
