import { axios } from '@/utils/request';

export function getPicos(searchParams) {
    let params = {
        page: searchParams?.page ? searchParams.page : 1,
        ...searchParams?.pageSize ? { per_page: searchParams.pageSize } : {},
        ...searchParams?.organization ? { organization_id: searchParams.organization } : {},
        ...searchParams?.organizationStatus ? { statuses: searchParams.organizationStatus } : {},
        ...searchParams?.sortField ? { order_column: searchParams.sortField } : {},
        ...searchParams?.sortOrder ? { order_direction: searchParams.sortOrder } : {},
        ...searchParams?.search ? { search: searchParams.search } : {},
    };

    const genParams = () => {
        return Object.keys(params).map(key => {
            if (key == 'statuses') {
                return params[key].map(item => {
                    return `${key}[]=${item}`
                }).join('&')
            } else {
                return `${key}=${params[key]}`
            }
        }).join('&')
    }

    return axios({
        url: `/picos?${genParams()}`,
        method: 'get'
    })
}

export function getPico(id){
    return axios({
        url: `/picos/${id}`,
        method: 'get'
    })
}

export function deletePico(id){
    return axios({
        url: `/picos/${id}`,
        method: 'delete'
    })
}

export function releasePico(id){
    return axios({
        url: `/picos/${id}/release`,
        method: 'get'
    })
}

export function updatePico(id, data){
    return axios({
        url: `/picos/${id}`,
        method: 'patch',
        data: data
    })
}