export const wrongCredentials = 'Credentials do not match or user doesn’t exist'
export const emailDoesntExist = 'Given email does not exist in the database'
export const passwordRegExp = 'Should contain letters and digits, min 5 and max 15 characters'
export const passwordRequired = 'Please input your password!'
export const requiredEmail = 'Email is required!'
export const requiredField = 'This field is required!'
export const passwordConfirm = 'Please confirm your password!'
export const emailNotValid = 'The input is not valid E-mail!'
export const emailIsInvalid = 'Invalid email entry!'
export const passwordIsNotValid = 'Password is not valid!'
export const minLength = 'Min length of a field - '
export const maxLength = 'Max length of a field - '
export const domainIsNotValid = 'The domain name has not been identified!'
export const userIdInteger = 'The User ID must be an integer!'


//Modal messages
export const logout = 'Are you sure you want to log out?'
export const registerLinkSent = 'Registration link is successfully sent to the Organizer.'
export const deleteOrganizationMassages = 'Organization will be deleted. Are you sure that want to continue?'
export const deleteBaseMassages = 'Base will delete. Are you sure that want to continue?'
export const deleteUserMassages = 'The user will be deleted. Are you sure you want to continue?'
export const deletePicoMassages = 'The pico will be deleted. Are you sure you want to continue?'
export const emailWasSent = 'Email for the recovery password was sent. Please check your email!'
