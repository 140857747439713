import { getList } from '@/api/languages';

const bases = {
    namespaced: true,
    state: {
        languagesList: []
    },
    getters: {
        getLanguagesList: state => state.languagesList
    },
    mutations: {
        SET_LANGUAGES_LIST(state, data){
            state.languagesList = data
        }
    },
    actions: {
        GetLanguagesList: (context) => {
            return new Promise((resolve, reject) => {
                getList().then(response => {
                    context.commit('SET_LANGUAGES_LIST', response.data);
                    resolve(response);
                }).catch(error => {
                    reject(error);
                })
            })
        }
    }
}

export default bases