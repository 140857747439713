<template>
  <a-layout-sider
      width="256px">
    <div class="logo">
      <router-link :to="{path: defaultPath}">
        <img src="@/assets/text-logo-white.svg" alt="logo">
      </router-link>
    </div>
    <div v-if="getRole">
      <s-menu :menu="finalMenu"></s-menu>
    </div>
    <div v-else>
      <div>
        <a-menu
            :default-selected-keys="['1']"
            mode="inline"
            theme="dark"
        >
          <a-menu-item key="1">
            <i class="anticon">
              <ProfileIcon alt="profileIcon" />
            </i>
            <span>{{ $t('basics.profile') }}</span>
          </a-menu-item>
        </a-menu>
      </div>
    </div>
    <div class="logout-link">
      <button @click="handleLogout">
        <i class="anticon">
          <Logout alt="logout" />
        </i>
        <span>{{$t('basics.logout')}}</span>
      </button>
    </div>
  </a-layout-sider>
</template>

<script>
import Logout from '@/assets/icons/logout.svg?inline'
import ProfileIcon from '@/assets/icons/list.svg?inline'
import { logout } from '@/utils/messages'
import SMenu from './index'
import { mapState } from 'vuex'
import { convertRoutes } from '@/utils/routeConvert'

export default {
  name: 'SideBar',
  components: {
    SMenu,
    Logout,
    ProfileIcon,
    logout,
  },
  data () {
    return {
      menus: [],
      finalMenu: [],
      statistics: false,
      statisticsSet: false
    }
  },
  computed: {
    ...mapState({
      mainMenu: state => state.permission.addRouters
    }),
    defaultPath() {
      //const path = parseInt(this.$store.getters['getRole']) === 1 ? '/dashboard/organization-list': '/dashboard/profile'
      return '/dashboard/organization-list';
    },
    getRole() {
      return parseInt(this.$store.getters['getRole'])
    },
    detailOrganization() {
      return this.$store.getters['detailOrganization/getOrganizationDetails']
    },
    organizationId() {
      return this.$store.getters['getOrganizationId']
    },
    getOrganizationsListAction() {
      return this.$store.dispatch('organizations/GetOrganizationsList')
    },
    getOrganizationsTableData() {
      return this.$store.getters['organizations/getOrganizationsTableData']
    },
  },
  created () {
    const routes = convertRoutes(this.mainMenu.find(item => item.path === '/'))
    this.menus = (routes && routes.children) || [];
    //if (this.organizationId) {
    //  this.$store.dispatch('detailOrganization/GetOrganization', this.organizationId);
    //} else {
      this.finalMenu = this.menus;
    //}
    if (this.getRole) {
      this.getOrganizationsListAction;
    }
  },
  watch: {
    getOrganizationsTableData: function() {
      if (this.getRole !== 1) {
        if (this.getOrganizationsTableData &&  this.getOrganizationsTableData.length > 0) {
          this.getOrganizationsTableData.map((value, key) => {
            if (value.statistic === true) {
              this.statistics = true;
            }
          });
          if (!this.statistics) {
            this.finalMenu = this.menus.filter(menuItem => {
              return menuItem.name !== "Statistics"
            });
          }
        }
      } else {
        this.finalMenu = this.menus;
      }
    },
    getRole: function() {
      if (this.getRole == 1) {
        this.finalMenu = this.menus;
      }
    }
  },
  methods: {
    handleLogout() {
      this.$confirm({
        centered: true,
        content: this.$t('messages.logout'),
        okText: this.$t('messages.logoutOk'),
        cancelText: this.$t('basics.cancel'),
        width: 490,
        closable: true,
        onOk: () => {
          this.$store.dispatch('Logout').then(() => {
            setTimeout(() => {
              window.location.reload()
            }, 16)
          }).catch(err => {
            this.$message.error({
              title: 'Error',
              description: err.message
            })
          })
        }
      })
    }
  }
}
</script>

<style lang="less">
.ant-layout-sider-children {
  display: flex;
  flex-direction: column;
}

.ant-layout-sider {
  padding-bottom: 50px;
}

.ant-menu.ant-menu-dark.ant-menu-inline  {
  border-top: 1px solid rgba(255, 255, 255, .2);
  padding: 4px 0;

  .ant-menu-item {
    font-size: 15px;
    border-radius: 4px;
    margin: 8px;
    width: initial;
    display: flex;
    align-items: center;
    padding: 0 8px !important;

    &:hover,
    &:hover > a {
      color: rgba(255, 255, 255, 0.75);
    }

    &.ant-menu-item-selected {
      &:hover,
      &:hover > a {
        color: #40454E;
      }
    }

    a {
      display: flex;
      align-items: center;
    }

    .anticon {
      font-size: 22px;
      margin-right: 35px;
    }
  }
}
</style>

<style lang="less" scoped>
.logo {
  padding: 30px 0px;
  text-align: center;
}

.logout-link {
  margin: auto 16px 0;

  button {
    background-color: transparent;
    display: flex;
    align-items: center;
    color: #ffffff;
    border: none;
    cursor: pointer;
    transition: color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
                border-color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
                background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
                padding 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);

    &:focus {
      outline: none;
    }

    &:hover {
      color: rgba(255, 255, 255, 0.75);
    }
  }

  i {
    margin-right: 10px;
  }

  svg {
    fill: #40454E;
  }
}
</style>
