import { getUser, deleteUser, updateUser } from '@/api/users';

const detailUser = {
    namespaced: true,
    state: {
        userDetails: {},
    },
    getters: {
        getUserDetails(state) {
            return state.userDetails
        },
    },
    mutations: {
        SET_USER_DETAIL(state, data) {
            state.userDetails = data;
        },
    },
    actions: {
        GetUser: (context, payload = '') => {
            let userId = typeof payload === 'object' ? payload.id : payload
            let getData = typeof payload === 'object' ?  new URLSearchParams(payload).toString() : ''
            return new Promise((resolve, reject) => {
                getUser(userId, getData).then(response => {
                    context.commit('SET_USER_DETAIL', response.data)
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },

        UpdateUser: (context, param) => {
            return new Promise((resolve, reject) => {
                updateUser(param.id, param.data).then(response => {
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },

        DeleteUser: (context, payload) => {
            let userId = typeof payload === 'object' ? payload.id : payload
            let getData = typeof payload === 'object' ?  new URLSearchParams(payload).toString() : ''
            return new Promise((resolve, reject) => {
                deleteUser(userId, getData).then(response => {
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        }
    }
}

export default detailUser