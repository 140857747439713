import { getOrganization, updateOrganization, updateOrganizationAutomation, deleteOrganization, updateImportTemplate, testConnectionAutomation, getSshKey } from '@/api/organizations';

const detailOrganization = {
    namespaced: true,
    state: {
        organizationDetails: {},
        organizationStatus: null
    },
    getters: {
        getOrganizationDetails(state) {
            return state.organizationDetails
        },
        getOrganizationStatus(state) {
            return state.organizationStatus
        }
    },
    mutations: {
        SET_ORGANIZATIONS_DETAIL(state, data) {
            state.organizationDetails = data;
        },
        GET_ORGANIZATIONS_STATUS(state, data) {
            state.organizationStatus = data;
        },
    },
    actions: {
        GetOrganization: (context, id) => {
            return new Promise((resolve, reject) => {
                getOrganization(id).then(response => {
                    context.commit('SET_ORGANIZATIONS_DETAIL', response.data)
                    context.commit('GET_ORGANIZATIONS_STATUS', response.data.status.id)
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },

        UpdateOrganization: (context, param) => {
            return new Promise((resolve, reject) => {
                updateOrganization(param.id, param.data).then(response => {
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },

        UpdateOrganizationAutomation: (context, param) => {
            return new Promise((resolve, reject) => {
                updateOrganizationAutomation(param.id, param.data).then(response => {
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },

        DeleteOrganization: (context, id) => {
            return new Promise((resolve, reject) => {
                deleteOrganization(id).then(response => {
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },

        UpdateImportTemplate: (context, param) => {
            return new Promise((resolve, reject) => {
                updateImportTemplate(param.id, param.data).then(response => {
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },

        TestConnectionAutomation: (context, param) => {
            return new Promise((resolve, reject) => {
                testConnectionAutomation(param.id, param.data).then(response => {
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },

        GetSshKey: (context, param) => {
            return new Promise((resolve, reject) => {
                getSshKey(param.id).then(response => {
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },
    }
}

export default detailOrganization