import Vue from 'vue'
import { login, recoverPassword, resetPassword, getLoggedUser, changePassword } from '@/api/auth';

const auth = {
    state: {
        token: null,
        emailSent: false,
        passwordReset: false,
        passwordExpired: false,
        userInfo: {},
        userOrganizationId: null,
        userOrganizationRoles: null,
        role: null,
        bgImagePath: "/auth_images/White4.png"
    },
    getters: {
        getRole: state => state.role,
        getEmailSentState: state => state.emailSent,
        getResetPasswordState: state => state.passwordReset,
        getPasswordExpiredState: state => state.passwordExpired,
        getOrganizationId: state => state.userOrganizationId,
        getOrganizationRoles: state => state.userOrganizationRoles,
        getBgImgPath: state => state.bgImagePath,
        me: state => state.userInfo
    },
    mutations: {
        SET_USER(state, user) {
            state.userInfo = user;
        },
        SET_ROLE(state, role) {
            state.role = role;
        },
        SET_TOKEN(state, token) {
            state.token = token;
        },
        SET_EMAIL_SENT(state, value) {
            state.emailSent = value;
        },
        SET_RESET_PASSWORD(state, value) {
            state.passwordReset = value;
        },
        SET_USER_ORGANIZATION_ID(state, value) {
            state.userOrganizationId = value;
        },
        SET_USER_ORGANIZATIONS_ROLES(state, value) {
            state.userOrganizationRoles = value;
        },
        SET_BG_IMAGE_PATH(state, value) {
            state.bgImagePath = value;
        },
        SET_PASSWORD_EXPIRED(state, value) {
            state.passwordExpired = value;
        }
    },
    actions: {
        Login(context, user) {
            return new Promise((resolve, reject) => {
                login(user).then(response => {
                    const token = response.data.access_token
                    Vue.ls.set('Authorization', token)
                    context.commit('SET_TOKEN', token)
                    resolve()
                }).catch(error => {
                    reject(error)
                })
            })
        },
        Logout() {
            Vue.ls.remove('Authorization')
            window.location = '/user/login';
        },
        GetLoggedUser: (context) => {
            return new Promise((resolve, reject) => {
                getLoggedUser().then(response => {
                    const result = response.data
                    context.commit('SET_USER', result)
                    context.commit('SET_ROLE', result.role.id)
                    context.commit('SET_PASSWORD_EXPIRED', result.password_expired ?? false)
                    context.commit('SET_USER_ORGANIZATION_ID', result.organization ? result.organization.id : null)
                    context.commit('SET_USER_ORGANIZATIONS_ROLES', result.user_organization_role)
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        RecoverPassword(context, email) {
            return new Promise((resolve, reject) => {
                recoverPassword(email).then(() => {
                    context.commit('SET_EMAIL_SENT', true)
                    context.commit('SET_BG_IMAGE_PATH', "/auth_images/White5.png");
                    resolve()
                }).catch(error => {
                    reject(error)
                })
            })
        },
        ReturnToLogin(context) {
            context.dispatch('Logout')
            context.commit('SET_EMAIL_SENT', false)
            context.commit('SET_RESET_PASSWORD', false)
        },
        ResetPassword(context, params) {
            return new Promise((resolve, reject) => {
                resetPassword(params).then(() => {
                    context.commit('SET_BG_IMAGE_PATH', "/auth_images/White6.png");
                    context.commit('SET_RESET_PASSWORD', true)
                    resolve()
                }).catch(error => {
                    reject(error)
                })
            })
        },
        ChangePassword(context, params) {
            return new Promise((resolve, reject) => {
                changePassword(params).then(() => {
                    context.commit('SET_BG_IMAGE_PATH', "/auth_images/White6.png");
                    context.commit('SET_PASSWORD_EXPIRED', false)
                    resolve()
                }).catch(error => {
                    reject(error)
                })
            })
        }
    },
}

export default auth
