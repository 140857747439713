import {getUsers, getListForOrganization, getUsersByEmail, importUsers, getUsersByEmailWithToken, createUser} from '@/api/users';

const users = {
    namespaced: true,
    state: {
        users: [],
        usersList: {},
        list: [],
        pageSize: 12,
        pageNumber: 1,
        filters: {},
        sortBy: {},
        searchValue: ''
    },
    getters: {
        getUsers: state => state.users,
        getUsersList: state => state.usersList,
        getListForOrganization: state => state.list,
        getUsersTableData: state => state.usersList.data,
        getPageSize: state => state.pageSize,
        getPageNumber: state => state.pageNumber,
    },
    mutations: {
        SET_USERS(state, data){
            state.users = data
        },
        SET_USERS_LIST(state, data){
            state.usersList = data
        },
        SET_LIST(state, data){
            state.list = data
        },
        SET_PAGE_NUMBER(state, data) {
            state.pageNumber = data
        },
        SET_PAGE_SIZE(state, data) {
            state.pageSize = data
        },
        SET_FILTERS(state, data){
            state.filters = data
        },
        SET_SORT(state, data){
            state.sortBy = data
        },
        SET_SEARCH_VALUE(state, data) {
            state.searchValue = data
        }
    },
    actions: {
        GetUsersList: (context, params) => {
            return new Promise((resolve, reject) => {
                getUsers({
                    ...params,
                    pageSize: context.state.pageSize,
                    ...params?.organizationStatus
                        ? { organizationStatus: params.organizationStatus }
                        : { organizationStatus: context.state.filters.organizationStatus },
                    ...params?.organization
                        ? { organization: params.organization }
                        : { organization: context.state.filters.organization },
                    ...params?.sortField
                        ? { sortField: params.sortField, sortOrder: params.sortOrder }
                        : { sortField: context.state.sortBy.sortField, sortOrder: context.state.sortBy.sortOrder },
                    ...params?.search
                        ? { search: params.search }
                        : { search: context.state.searchValue }
                }).then(response => {
                    context.commit('SET_PAGE_NUMBER', response.meta.current_page)
                    context.commit('SET_USERS_LIST', response)
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        GetList: (context, params) => {
            return new Promise((resolve, reject) => {
                getListForOrganization(params).then(response => {
                    context.commit('SET_LIST', response.data);
                    resolve(response.data);
                }).catch(error => {
                    reject(error);
                })
            })
        },
        ImportUsers: (context, data) => {
            return new Promise((resolve, reject) => {
                importUsers(data).then(response => {
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        GetUsersByEmail: (context, email) => {
            return new Promise((resolve, reject) => {
                getUsersByEmail(email).then(response => {
                    context.commit('SET_USERS', response.data);
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        GetUsersByEmailWithToken: (context, {email, token}) => {
            return new Promise((resolve, reject) => {
                getUsersByEmailWithToken(token, email).then(response => {
                    context.commit('SET_USERS', response.data);
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        CreateUser: (context, data) => {
            return new Promise((resolve, reject) => {
                createUser(data).then(response => {
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        }
    }
}

export default users