import { getPico, deletePico, releasePico, updatePico } from '@/api/picos';

const detailPico = {
    namespaced: true,
    state: {
        picoDetails: {},
    },
    getters: {
        getPicoDetails(state) {
            return state.picoDetails
        },
    },
    mutations: {
        SET_PICO_DETAIL(state, data) {
            state.picoDetails = data;
        },
    },
    actions: {
        GetPico: (context, id) => {
            return new Promise((resolve, reject) => {
                getPico(id).then(response => {
                    context.commit('SET_PICO_DETAIL', response.data)
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        DeletePico: (context, id) => {
            return new Promise((resolve, reject) => {
                deletePico(id).then(response => {
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        ReleasePico: (context, id) => {
            return new Promise((resolve, reject) => {
                releasePico(id).then(response => {
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        UpdatePico: (context, param) => {
            return new Promise((resolve, reject) => {
                updatePico(param.id, param.data).then(response => {
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        }
    }
}

export default detailPico