import Vue from 'vue'
import axios from 'axios'
import store from '@/store'

axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL

const axiosAgent = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL,
  timeout: 6000
})

const err = (error) => {
  if (error.response) {
    const token = Vue.ls.get('Authorization')
    if (error.response.status === 401) {
      store.dispatch('Logout')
    }
  }
  return Promise.reject(error)
}

// request interceptor
axiosAgent.interceptors.request.use(config => {
  const token = Vue.ls.get('Authorization')
  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`
  }
  config.headers['X-Language'] = `${localStorage.locale ? localStorage.locale : 'en'}`
  return config
}, err)

// response interceptor
axiosAgent.interceptors.response.use((response) => {
  return response.data
}, err)


export {
  axiosAgent as axios
}
