import { getGeneralStats, getUserStats, getTimeStats, getPicoStats } from '@/api/statistics';

const bases = {
    namespaced: true,
    state: {
        general: {},
        users: [],
        time: {},
        picos: {},
    },
    getters: {
        generalStats: state => state.general,
        usersStats: state => state.users,
        timeStats: state => state.time,
        picosStats: state => state.picos,
    },
    mutations: {
        SET_GENERAL_STATS(state, data) {
            state.general = data
        },
        SET_USERS_STATS(state, data) {
            state.users = data
        },
        SET_TIME_STATS(state, data) {
            state.time = data
        },
        SET_PICOS_STATS(state, data) {
            state.picos = data
        },
    },
    actions: {
        GetGeneralStats: (context, {organizationId: organizationId, params}) => {
            return new Promise((resolve, reject) => {
                getGeneralStats(organizationId, params).then(response => {
                    context.commit('SET_GENERAL_STATS', response.data);
                    resolve(response);
                }).catch(error => {
                    reject(error);
                })
            })
        },
        GetUsersStats: (context, {organizationId: organizationId, params}) => {
            return new Promise((resolve, reject) => {
                getUserStats(organizationId, params).then(response => {
                    context.commit('SET_USERS_STATS', response.data);
                    resolve(response);
                }).catch(error => {
                    reject(error);
                })
            })
        },
        GetTimeStats: (context, {organizationId: organizationId, params}) => {
            return new Promise((resolve, reject) => {
                getTimeStats(organizationId, params).then(response => {
                    context.commit('SET_TIME_STATS', response.data);
                    resolve(response);
                }).catch(error => {
                    reject(error);
                })
            })
        },
    }
}

export default bases