import Vue from 'vue'
import VueI18n from 'vue-i18n'
import { messages } from '@/locales/localization'

Vue.use(VueI18n)

export const i18n = new VueI18n({
  locale: localStorage.locale ? localStorage.locale : 'en',
  fallbackLocale: 'en',
  messages,
})