import { AuthLayout, AdminLayout, CreateOrganizationAdminLayout } from '@/layouts'
import { user, organization, bases, picos, statistic } from '@/core/icons'
import { i18n } from '@/i18n';

export const asyncRouterMap = [
  
  {
    path: '/',
    name: 'index',
    component: AdminLayout,
    meta: { title: 'Dashboard' },
    children: [
      {
        path: 'dashboard',
        name: 'Dashboard',
        meta: { title: i18n.t('basics.dashboard'), icon: statistic, permission: [ 'dashboard' ], outer: true }
      },
      {
        path: '/dashboard/organization-list',
        name: 'OrganizationList',
        component: () => import('@/views/admin/OrganizationList/OrganizationList'),
        meta: { title: i18n.t('organizations.organizations_list'), keepAlive: true, icon: organization, permission: [ 'organization-list' ] }
      },
      {
        path: '/dashboard/profile',
        name: 'Profile',
        component: () => import('@/views/admin/UserProfile/UserProfile'),
        meta: { title: i18n.t('basics.profile'), keepAlive: true, icon: organization, permission: [ 'profile' ] }
      },
      {
        path: '/dashboard/bases-list',
        name: 'BasesList',
        component: () => import('@/views/admin/BasesList/BasesList'),
        meta: { title: i18n.t('bases.bases_list'), keepAlive: true, icon: bases, permission: [ 'bases-list' ] }
      },
      {
        path: '/dashboard/picos-list',
        name: 'PicosList',
        component: () => import('@/views/admin/PicosList/PicosList'),
        meta: { title: i18n.t('picos.picos_list'), keepAlive: true, icon: picos, permission: [ 'picos-list' ] }
      },
      {
        path: '/dashboard/user-list',
        name: 'UserList',
        component: () => import('@/views/admin/UsersList/UsersList'),
        meta: { title: i18n.t('users.users_list'), keepAlive: true, icon: user, permission: [ 'user-list' ] }
      },
      {
        path: '/dashboard/statistics',
        name: 'Statistics',
        component: () => import('@/views/admin/Statistics/Statistics'),
        meta: { title: i18n.t('basics.statistics'), keepAlive: true, icon: statistic, permission: [ 'statistics' ] }
      },
      {
        path: 'tickets',
        name: 'Tickets',
        meta: { title: i18n.t('basics.tickets'), icon: organization, permission: [ 'tickets' ], outer: true }
      },
    ]
  },
  {
    path: '*', redirect: '/404', hidden: true
  },
  {
    path: '/qr-code',
    name: 'QRcodePage',
    component: () => import('@/views/admin/QRcodePage/QRcodePage')
  },
  {
    path: '/',
    component: AuthLayout,
    name: 'verify/:id/:hash',
    children: [
      {
        path: 'verify',
        name: 'EmailVerified',
        component: () => import('@/views/auth/EmailVerified/EmailVerified')
      }
    ]
  },
]

export const constantRouterMap = [
  {
    path: '/user',
    component: AuthLayout,
    name: 'auth',
    redirect: '/user/login',
    hidden: true,
    children: [
      {
        path: 'login',
        name: 'Login',
        component: () => import(/* webpackChunkName: "login" */ '@/views/auth/Login/Login')
      },
      {
        path: 'password-recovery',
        name: 'ForgotPassword',
        component: () => import(/* webpackChunkName: "login" */ '@/views/auth/ForgotPassword/ForgotPassword')
      },
      {
        path: 'reset-password',
        name: 'ResetPassword',
        component: () => import(/* webpackChunkName: "login" */ '@/views/auth/ResetPassword/ResetPassword')
      },
      {
        path: '/password/change',
        name: 'ChangePassword',
        component: () => import('@/views/auth/ChangePassword/ChangePassword')
      }
    ]
  },
  {
    path: '/',
    component: AuthLayout,
    name: 'verify',
    children: [
      {
        path: 'verify/:id/:hash',
        name: 'EmailVerified',
        component: () => import('@/views/auth/EmailVerified/EmailVerified')
      }
    ]
  },
  {
    path: '/user',
    component: CreateOrganizationAdminLayout,
    name: 'user',
    redirect: '/user/login',
    hidden: true,
    children: [
      {
        path: 'create-organization',
        name: 'CreateOrganization',
        component: () => import(/* webpackChunkName: "login" */ '@/views/user/CreateOrganizationUser/CreateOrganizationUser')
      }
    ]
  },
  {
    path: '/404',
    component: () => import(/* webpackChunkName: "fail" */ '@/views/exception/404')
  }
]
