import Echo from 'laravel-echo';

let authToken = localStorage.getItem('pro__Authorization');

if (authToken) {
    authToken = JSON.parse(authToken).value;
}

const Socket = {
    install(Vue) {
        window.io = require('socket.io-client');
        Vue.prototype.$echo = new Echo({
            broadcaster: 'socket.io',
            host: process.env.VUE_APP_API_SOCKET_URL,
            auth: {
                headers: {
                    Authorization: `Bearer ${authToken}`
                }
            }
        });
    }
};

export default Socket;
