<template>
  <a-layout>
    <side-bar></side-bar>
    <a-layout-content class="main admin-layout-content">
      <route-view></route-view>
    </a-layout-content>
  </a-layout>
</template>

<script>
import RouteView from './RouteView'
import SideBar from '@/components/Menu/SideBar'

export default {
  name: 'AdminLayout',
  components: {
    RouteView,
    SideBar
  }
}
</script>

<style lang="less">
.main-content {
  height: 100%;

  &-wrapp {
    padding: 40px;
  }
}

.modal-opened {
  filter: blur(2px);
}

.admin-layout__list-filters {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

.admin-layout__filters {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
</style>

<style lang="less" scoped>
.main {
  position: relative;
}

.ant-layout {
  &.ant-layout-has-sider {
    height: 100%;
  }
}
</style>
