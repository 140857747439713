import {
    sendCreateOrganizationLink,
    createOrganizations,
    createOrganizationsByToken } from '@/api/organizations';

const createOrganization = {
    namespaced: true,
    state: {
        currentStep: 0
    },
    getters: {
        getCurrentStep: state => state.currentStep,
    },
    mutations: {
        SET_CURRENT_STEP(state, value) {
            state.currentStep = value;
        }
    },
    actions: {
        SendCreateOrganizationLink(context, email) {
            return new Promise((resolve, reject) => {
                sendCreateOrganizationLink(email).then(() => {
                    resolve()
                }).catch(error => {
                    reject(error)
                })
            })
        },
        CreateOrganization(context, data) {
            return new Promise((resolve, reject) => {
                createOrganizations(data).then(()=> {
                    resolve()
                }).catch(error => {
                    reject(error)
                })
            })
        },
        CreateOrganizationsByToken(context, data) {
            return new Promise((resolve, reject) => {
                createOrganizationsByToken(data.token, data.data).then(()=> {
                    resolve()
                }).catch(error => {
                    reject(error)
                })
            })
        }
    },
}

export default createOrganization
