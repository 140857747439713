import { getPicos } from '@/api/picos';

const picos = {
    namespaced: true,
    state: {
        picosList: {},
        pageSize: 12,
        pageNumber: 1,
        filters: {},
        sortBy: {},
        searchValue: ''
    },
    getters: {
        getPicosList: state => state.picosList,
        getPicosTableData: state => state.picosList.data,
        getPageSize: state => state.pageSize,
        getPageNumber: state => state.pageNumber,
        getFilters: state => state.filters
    },
    mutations: {
        SET_PICOS_LIST(state, data){
            state.picosList = data
        },
        SET_PAGE_NUMBER(state, data) {
            state.pageNumber = data
        },
        SET_PAGE_SIZE(state, data) {
            state.pageSize = data
        },
        SET_FILTERS(state, data){
            state.filters = data
        },
        SET_SORT(state, data){
            state.sortBy = data
        },
        SET_SEARCH_VALUE(state, data) {
            state.searchValue = data
        }
    },
    actions: {
        GetPicosList: (context, params) => {
            return new Promise((resolve, reject) => {
                getPicos({
                    ...params,
                    pageSize: context.state.pageSize,
                    ...params?.organizationStatus
                        ? { organizationStatus: params.organizationStatus }
                        : { organizationStatus: context.state.filters.organizationStatus },
                    ...params?.organization
                        ? { organization: params.organization }
                        : { organization: context.state.filters.organization },
                    ...params?.sortField
                        ? { sortField: params.sortField, sortOrder: params.sortOrder }
                        : { sortField: context.state.sortBy.sortField, sortOrder: context.state.sortBy.sortOrder },
                    ...params?.search
                        ? { search: params.search }
                        : { search: context.state.searchValue }
                }).then(response => {
                    context.commit('SET_PAGE_NUMBER', response.meta.current_page)
                    context.commit('SET_PICOS_LIST', response)
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        }
    }
}

export default picos