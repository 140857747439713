import Vue from 'vue'
import Vuex from 'vuex'
import permission from './modules/permission'
import auth from './modules/auth'
import organizations from './modules/organizations'
import createOrganization from './modules/createOrganization'
import detailOrganization from './modules/detailOrganization'
import bases from './modules/bases'
import detailBase from './modules/detailBase'
import picos from './modules/picos'
import detailPico from './modules/detailPico'
import users from './modules/users'
import languages from './modules/languages'
import detailUser from "./modules/detailUser";
import statistics from "./modules/statistics";

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        auth,
        permission,
        organizations,
        createOrganization,
        detailOrganization,
        detailUser,
        bases,
        detailBase,
        picos,
        detailPico,
        users,
        languages,
        statistics
    }
})
