import { axios } from '@/utils/request'

export function login(parameter) {
  return axios({
    url: '/auth/login',
    method: 'post',
    data: parameter
  })
}

export function recoverPassword(email) {
  return axios({
    url: '/password/reset',
    method: 'post',
    data: email
  });
}

export function resetPassword(newPassword) {
  return axios({
    url: '/password/update',
    method: 'post',
    data: newPassword
  })
}

export function verifyEmail(path) {
  return axios({
    url: `/mobile/auth${path}`,
    method: 'get'
  })
}

export function getLoggedUser() {
  return axios({
    url: '/auth/me',
    method: 'get'
  })
}

export function changePassword(newPassword) {
  return axios({
    url: '/auth/password/change',
    method: 'post',
    data: newPassword
  })
}