import { axios } from '@/utils/request';

export function getBases(searchParams) {
    let params = {
        page: searchParams?.page ? searchParams.page : 1,
        ...searchParams?.pageSize ? { per_page: searchParams.pageSize } : {},
        ...searchParams?.organization ? { organization_id: searchParams.organization } : {},
        ...searchParams?.organizationStatus ? { statuses: searchParams.organizationStatus } : {},
        ...searchParams?.sortField ? { order_column: searchParams.sortField } : {},
        ...searchParams?.sortOrder ? { order_direction: searchParams.sortOrder } : {},
        ...searchParams?.search ? { search: searchParams.search } : {},
    };

    const genParams = () => {
        return Object.keys(params).map(key => {
            if (key == 'statuses') {
                return params[key].map(item => {
                    return `${key}[]=${item}`
                }).join('&')
            } else {
                return `${key}=${params[key]}`
            }
        }).join('&')
    }

    return axios({
        url: `/bases?${genParams()}`,
        method: 'get'
    })
}

export function createBase(data) {
    return axios({
        url: '/bases',
        method: 'post',
        data: data
    })
}

export function getBase(id){
    return axios({
        url: `/bases/${id}`,
        method: 'get'
    })
}

export function getBasesList(organizationId){
    return axios({
        url: `/bases/list?organization_id=${organizationId}`,
        method: 'get'
    })
}

export function updateBase(id, data){
    return axios({
        url: `/bases/${id}`,
        method: 'patch',
        data: data
    })
}

export function deleteBase(id){
    return axios({
        url: `/bases/${id}`,
        method: 'delete'
    })
}

export function releaseSlot(id, slot){
    return axios({
        url: `/bases/${id}/release/${slot}`,
        method: 'get'
    })
}

export function advancedUpdate(id){
    return axios({
        url: `/bases/${id}/advanced/update`,
        method: 'get'
    })
}

export function advancedSync(id){
    return axios({
        url: `/bases/${id}/advanced/sync`,
        method: 'get'
    })
}

export function unlockAllPicos(id){
    return axios({
        url: `/bases/${id}/picos/unlock`,
        method: 'put'
    })
}

export function changePicosVersion(id, data) {
    return axios({
        url: `/bases/${id}/picos-version`,
        method: 'put',
        data: data
    });
}

export function setAutoReboot(id, data) {
    return axios({
        url: `/bases/${id}/auto-reboot`,
        method: 'put',
        data: data
    });
}

export function setScreensaver(id, data) {
    return axios({
        url: `/bases/${id}/screensaver`,
        method: 'put',
        data: data
    });
}